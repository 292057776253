import { useState, useEffect } from "react";


type WindowSize = {
    width: number;
    height: number;
}

const useWindowSize = () => {

    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: -1,
        height: -1,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;

};

export default useWindowSize;