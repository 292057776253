import styled from "styled-components";
import './Post/post.sass'
import {home} from "../common/content";
import {Link} from '@reach/router';
import {motion} from "framer-motion";
import React from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    place-content: space-between flex-start;
    -webkit-box-pack: start;
    -webkit-box-align: baseline;
    align-items: baseline;
    width: 100%;
    margin-bottom: 0.5em;
`
const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  align-items: baseline;
  margin: 0em 0em 0em 0;
  width: 50%;
    @media (max-width: 1000px) {
        width: 100%;
    }
`


const Head = styled(motion.h1)`
  font-size: 24px;
  margin: 1em 0 0.1em 0;
  font-family: "rel",sans-serif;
`;


const Header = (): JSX.Element => <Container>
    <HeadContainer>
        <Head
            whileHover={{scale: 1.05}}
            whileTap={{scale: 0.99}}
        >
            <Link to={"/"}>
                {home.title}
            </Link>
        </Head>
    </HeadContainer>
</Container>


    export default Header;