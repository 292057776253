import styled from "styled-components";
import './Post/post.sass'
import {Body} from "./Post/Post";
import {home} from "../common/content";
import {motion} from "framer-motion";
import React from "react";

const BioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  width: 60%;
`

const Bio = () => <BioContainer>
    <motion.div
        initial={{y: "-50px"}}
        animate={{y: "0px"}}
        exit={{y: "-50px"}}
        transition={{duration: 0.45, type: "spring"}}
        className={"post"}
    >
        <Body md={home.about.body}/>
    </motion.div>
</BioContainer>;

    export default Bio;