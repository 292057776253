const seel = (p: any) => {
    let palette = ['#1263ba', '#ffdb5b', '#f23d21', '#1f1d20', '#dec8b7'];
    let minS = 20;
    const rectRec = (x: number, y: number, w: number, ww?: number) => {
        let c = p.int(p.random(2, 4));
        let sw = w / c;
        let rnd = p.random(1);
        let pp = p.map(w, minS, p.width, 0, 1);
        let corner = 0;
        let pad = 10;

        if (rnd < pp) {
            for (let i = 0; i < c; i++) {
                for (let j = 0; j < c; j++) {
                    if (sw > minS) {
                        rectRec(x + i * sw, y + j * sw, sw);
                    } else {
                        p.fill(p.random(palette));
                        p.rect(x + i * sw, y + j * sw, sw, sw, corner);
                    }
                }
            }
        } else {
            let off = minS * 1;
            let ww = w - off;
            p.fill(p.random(palette));
            p.rect(x, y, w, w, corner);
            if (ww > minS) {
                rectRec(x + off / 2, y + off / 2, ww, ww);
            }
        }
    };
    p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight, p.WEBGL);
        p.noLoop();
    };
    p.windowResized = () =>  p.resizeCanvas(p.windowWidth, p.windowHeight)
    p.draw = () => {
        p.background("#f6f4f2");
        p.stroke(0);
        p.strokeWeight(2);
        rectRec(200, (-p.height/4), p.width/3, p.height/2);
    };

};

export default seel;