import contentJson from '../content.json';
import { post, postRef, ppost, content } from '../types'

const fetch_refs = (cont: ppost[], cate: string): postRef[] => {
    // tslint:disable-next-line: prefer-const
    let refs: postRef[] = []
    // tslint:disable-next-line: prefer-const
    cont.forEach((p: ppost) => {
        refs.push({ id: p.id, title: p.title, date: p.date, url: `${p.title.split(' ').join('-')}` });
    })
    return refs;
}

export const home = contentJson.home;

