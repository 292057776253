import React, {useState, useCallback, useEffect} from 'react';
import seel from "./common/component/seel";
import P5 from "@mohfunk/react-p5-component"
import Header from "./components/Header";
import {Router, Location} from '@reach/router'
import {CSSTransition, TransitionGroup} from "react-transition-group";
import useWindowSize from './common/hooks/useWindowSize';
import Bio from "./components/Bio";

const Home = ({path, selectedMenu, menuChange}: { path: string, selectedMenu: number, menuChange: any}) => {
    const [transitionFromTab, setTransitionFromTab] = useState(0);
    const onChangeSelectedMenu = useCallback((i: number) => {
        const transition = i > selectedMenu ? 1 : -1;
        menuChange(i)
        setTransitionFromTab(transition);
    }, []);

    useEffect(() => {
        setTransitionFromTab(0);
    }, []);
    return (
        <div className={"page"}>
            {selectedMenu === 0 && <Bio />}
        </div>
    )

}

function App() {

    const [selectedMenu, setSelectedMenu] = useState(0);
    const onChangeSelectedMenu = useCallback((i: number) => setSelectedMenu(i), []);
    const windowSize = useWindowSize();

    return (
        <div className={"app"}>
            <div className="content">
                <Header/>
                <Location>
                    {({ location }) => (
                        <TransitionGroup className="transition-group">
                            <CSSTransition key={location.key} classNames="fade" timeout={450}>
                                <Router location={location} className={"router"}>
                                    <Home path={'/'} selectedMenu={selectedMenu} menuChange={onChangeSelectedMenu}/>
                                </Router>
                            </CSSTransition>
                        </TransitionGroup>
                    )}
                </Location>
                {windowSize.width >= 1200 &&  <P5 sketch={seel}/> }
               
            </div>
        </div>
    );
}

export default App;
