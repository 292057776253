import React, {useEffect, useMemo} from "react";
import {post, date, imginfo, copyright} from '../../types'
import './post.sass';
import {motion, AnimatePresence} from "framer-motion";
import {Link} from '@reach/router';
import hljs from "highlight.js";
// @ts-ignore
import javascript from 'highlight.js/lib/languages/javascript';

hljs.registerLanguage('javascript', javascript);
const markd = require('markdown-it')({
    breaks: false,
    highlight: (str: string, lang: string) => {
        if (lang && hljs.getLanguage(lang)) {
            try {
                //@ts-ignore
                return '<pre class="hljs"><code>' + hljs.highlight(str, {
                    language: lang,
                    ignoreIllegals: true
                }).value + '</code></pre>';
            } catch (__) {
            }
        }
        return '<pre class="hljs"><code>' + markd.utils.escapeHtml(str) + '</code></pre>';
        ;
    },
    langPrefix: "hljs-"
})


type BodyProps = {
    md: string[]
}

export const Body = ({md}: BodyProps): JSX.Element => {

    const content = useMemo(() => {
        let start = 0;
        let start_i = 0;
        for (let i = 0; i < md.length; i++) {
            if (md[i].startsWith("```")) {
                if (start === 1) {
                    const b = md.splice(start_i, i - start_i + 1)
                    const bs = b.join('\n')
                    md.splice(start_i, 0, bs)
                    start = 0;
                    i = start_i + 1
                    start_i = 0
                } else {
                    start = 1;
                    start_i = i;
                    start = 1;
                    start_i = i;
                }
            } else {
                continue
            }
        }
        return md.map((line: string) => markd.render(line))
    }, [md]);
    return (
        <span dangerouslySetInnerHTML={{__html: content.join(' ')}}/>
    )
}
type HeaderProps = {
    title: string
}
const Header = ({title}: HeaderProps): JSX.Element => {
    return (
        <h1>{title}</h1>
    )
}

type BannerProps = {
    info: imginfo
}
const imgref = (p: string): { __html: string } => ({
    __html: `<img src="${p}"/>`,
})
const Banner = ({info}: BannerProps): JSX.Element => {

    return (
        <>
            {info.path !== null && <span className={"banner"} dangerouslySetInnerHTML={imgref(info.path)} />}
            <h6>{info.label} <span><Link to={info.url}>{info.source}</Link></span></h6>
        </>
    )
}

type Props = {
    data: any
    path: string
}

const Post = ({data}: Props): JSX.Element => {
    return (
        <div className={"page"}>
            <AnimatePresence>
                <motion.div
                    initial={{y: "-50px"}}
                    animate={{y: "0px"}}
                    exit={{y: "-50px"}}
                    transition={{duration: 0.45, type: "spring"}}
                    className={"post"}
                >
                    <Header title={data.title}/>
                    <Banner info={data.imag}/>
                    <Body md={data.body}/>
                </motion.div>
            </AnimatePresence>
        </div>
    )
}

export default Post;